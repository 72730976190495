import type { ForwardedRef, PropsWithChildren } from "react";
import { forwardRef } from "react";
import type { FlexProps } from "../../../flex/src";
import { Direction, Flex } from "../../../flex/src";
type GroupProps = Omit<FlexProps, "direction">;
function Group(props: PropsWithChildren<GroupProps>, ref: ForwardedRef<HTMLDivElement>) {
  const {
    children,
    ...other
  } = props;
  return <Flex ref={ref} direction={Direction.horizontal} {...other} data-sentry-element="Flex" data-sentry-component="Group" data-sentry-source-file="shared-ds-group.tsx">
      {children}
    </Flex>;
}
const ForwardedGroup = forwardRef<HTMLDivElement, PropsWithChildren<GroupProps>>(Group);
export { ForwardedGroup as Group };
export type { GroupProps };