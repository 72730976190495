import { Array as A, Function as F, Option as O } from "effect";

/**
 * Ensure an `Array` has exactly the expected number of elements, dropping or adding items as required.
 *
 * @param self - The Array to extend or shrink
 * @param size - The required number of elements
 * @param onNone - Function that returns the default value to use when adding items to the Array.
 *
 * @example
 * const onNone = (): string => "X";
 *
 * assert.deepStrictEqual(ensureSize([], 3, onNone), ["X", "X", "X"])
 * assert.deepStrictEqual(ensureSize(["1", "2"], 3, onNone), ["1", "2", "X"])
 * assert.deepStrictEqual(ensureSize(["1", "2", "3", "4"], 3, onNone), ["1", "2", "3"])
 *
 * assert.deepStrictEqual(ensureSize(3, onNone)([]), ["X", "X", "X"])
 */
const ensureSize: {
  <T>(
    size: number,
    onNone: F.LazyArg<T>,
  ): (self: readonly T[]) => A.NonEmptyArray<T>;
  <T>(
    self: readonly T[],
    size: number,
    onNone: F.LazyArg<T>,
  ): A.NonEmptyArray<T>;
} = F.dual(
  3,
  <T>(
    self: readonly T[],
    size: number,
    onNone: F.LazyArg<T>,
  ): A.NonEmptyArray<T> =>
    F.pipe(
      A.range(0, size - 1),
      A.map((index) => A.get(self, index)),
      A.map(O.getOrElse(onNone)),
    ),
);

export { ensureSize };
