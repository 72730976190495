import type { AST, Schema } from "@effect/schema";
import type { Array as A } from "effect";

const castEnum = <Literals extends A.NonEmptyReadonlyArray<AST.LiteralValue>>(
  schema: Schema.Literal<Literals>,
): {
  //@ts-expect-error Literals[number] is equivalent to string | number | boolean | bigint | null, which are fine as keys
  readonly [K in Literals[number]]: K;
} => {
  return Object.fromEntries(schema.literals.map((v) => [v, v]));
};

export { castEnum };
