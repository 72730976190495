export {
  H1,
  H2,
  H3,
  H4,
  H5,
  Heading,
  Level,
  LevelValues,
} from "./lib/shared-ds-heading";
export type { HeadingProps, Levels } from "./lib/shared-ds-heading";
