import { Schema } from "@effect/schema";
import type { MouseEvent, ReactNode } from "react";

import { castEnum } from "@ender/shared/utils/effect";
import type { Colors } from "@ender/shared/utils/theming";
import { SizeSchema } from "@ender/shared/utils/theming";

const ButtonSizeSchema = SizeSchema.pipe(
  Schema.pickLiteral("sm", "md", "lg", "xl"),
);
const ButtonSizeValues = ButtonSizeSchema.literals;
type ButtonSizes = Schema.Schema.Type<typeof ButtonSizeSchema>;
const ButtonSize = castEnum(ButtonSizeSchema);

const ButtonVariantSchema = Schema.Literal("filled", "outlined", "transparent");
const ButtonVariantValues = ButtonVariantSchema.literals;
type ButtonVariants = Schema.Schema.Type<typeof ButtonVariantSchema>;
const ButtonVariant = castEnum(ButtonVariantSchema);

type ButtonProps = {
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  size?: ButtonSizes;
  variant?: ButtonVariants;
  color?: Extract<Colors, "red" | "green" | "primary" | "slate">;
  disabled?: boolean;
  loading?: boolean;
  tooltip?: ReactNode;
  disabledTooltip?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset";
};

export {
  ButtonSize,
  ButtonSizeSchema,
  ButtonSizeValues,
  ButtonVariant,
  ButtonVariantSchema,
  ButtonVariantValues,
};
export type { ButtonProps, ButtonSizes, ButtonVariants };
