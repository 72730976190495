import { Function as F, Option as O } from "effect";

/**
 * Maps two `Option` values together using a provided function, returning a new `Option` of the result.
 *
 * @param self - The left-hand side of the zip operation
 * @param that - The right-hand side of the zip operation
 * @param fn - The function used to combine the values of the two `Option`s
 *
 * @example
 * const sum = (args: number[]): number => args.reduce((acc, cur) => acc + cur, 0);
 *
 * assert.deepStrictEqual(mapTuple(Option.none(), Option.none(), sum), Option.none())
 * assert.deepStrictEqual(mapTuple(Option.some(1), Option.none(), sum), Option.some(1))
 * assert.deepStrictEqual(mapTuple(Option.none(), Option.some(2), sum), Option.some(2))
 * assert.deepStrictEqual(mapTuple(Option.some(1), Option.some(2), sum), Option.some(3))
 *
 * assert.deepStrictEqual(mapTuple(Option.some(1), sum)(Option.some(2)), Option.some(3))
 */
const mapTuple: {
  <T>(
    that: O.Option<T>,
    fn: (ab: T[]) => T,
  ): (self: O.Option<T>) => O.Option<T>;
  <T>(self: O.Option<T>, that: O.Option<T>, fn: (ab: T[]) => T): O.Option<T>;
} = F.dual(
  3,
  <T>(self: O.Option<T>, that: O.Option<T>, fn: (ab: T[]) => T): O.Option<T> =>
    F.pipe(
      self,
      O.zipWith(that, (a: T, b: T) => fn([a, b])),
      O.orElse(F.flow(F.constant(self), O.orElse(F.constant(that)))),
    ),
);

export { mapTuple };
