import { Schema } from "@effect/schema";

import { castEnum } from "@ender/shared/utils/effect";

//COLOR
const ColorSchema = Schema.Literal(
  "primary",
  "red",
  "orange",
  "yellow",
  "green",
  "slate",
);
const ColorValues = ColorSchema.literals;
type Colors = Schema.Schema.Type<typeof ColorSchema>;
const Color = castEnum(ColorSchema);

//SPACING, SIZE
const SizeSchema = Schema.Literal("xs", "sm", "md", "lg", "xl");
const SizeValues = SizeSchema.literals;
type Sizes = Schema.Schema.Type<typeof SizeSchema>;
const Size = castEnum(SizeSchema);

export { Color, ColorSchema, ColorValues, Size, SizeSchema, SizeValues };

export type { Colors, Sizes };
