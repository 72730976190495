import { Schema } from "@effect/schema";
// eslint-disable-next-line ender-rules/erroneous-import-packages
import { Flex as RadixFlex } from "@radix-ui/themes";
import { cva } from "class-variance-authority";
import type { ForwardedRef, PropsWithChildren } from "react";
import { forwardRef } from "react";
import { castEnum } from "@ender/shared/utils/effect";
import { SizeSchema } from "@ender/shared/utils/theming";
const SpacingSchema = Schema.Literal(...SizeSchema.pipe(Schema.pickLiteral("xs", "sm", "md", "lg", "xl")).literals, "none");
const SpacingValues = SpacingSchema.literals;
type Spacings = Schema.Schema.Type<typeof SpacingSchema>;
const Spacing = castEnum(SpacingSchema);
const DirectionSchema = Schema.Literal("horizontal", "vertical");
const DirectionValues = DirectionSchema.literals;
type Directions = Schema.Schema.Type<typeof DirectionSchema>;
const Direction = castEnum(DirectionSchema);
const JustifySchema = Schema.Literal("normal", "start", "end", "center", "between", "stretch");
const JustifyValues = JustifySchema.literals;
type Justifications = Schema.Schema.Type<typeof JustifySchema>;
const Justify = castEnum(JustifySchema);
const AlignSchema = Schema.Literal("start", "end", "center", "stretch");
const AlignValues = AlignSchema.literals;
type Alignments = Schema.Schema.Type<typeof AlignSchema>;
const Align = castEnum(AlignSchema);
const OverflowSchema = Schema.Literal("auto", "hidden", "visible");
const OverflowValues = OverflowSchema.literals;
type Overflows = Schema.Schema.Type<typeof OverflowSchema>;
const Overflow = castEnum(OverflowSchema);
type FlexProps = {
  /**
   * the gap between child items
   * none: 0
   * xs: 0.25rem (4px)
   * sm: 0.5rem (8px)
   * md: 1rem (16px)
   * lg: 1.5rem (24px)
   * xl: 2rem (32px)
   */
  spacing?: Spacings;
  /**
   * whether the items should be laid out in a horizontal row or a vertical column
   * @default horizontal
   */
  direction?: Directions;
  /**
   * the orientation of items along the main axis
   */
  justify?: Justifications;
  /**
   * the orientation of items along the cross axis
   */
  align?: Alignments;
  /**
   * prevent the items from wrapping to the next line if they overflow the container
   */
  noWrap?: boolean;
  /**
   * control the overflow behavior of the container
   */
  overflow?: Overflows;
  /**
   * whether this container should grow to fill the available space
   */
  grow?: boolean;
  /**
   * whether this container should shrink. allows the container to be smaller than its contents, to a minimum of 0
   */
  shrink?: boolean;
  /**
   * fill the width of the nearest positioned ancestor
   */
  fullWidth?: boolean;
  /**
   * fill the height of the nearest positioned ancestor
   */
  fullHeight?: boolean;
};
const FlexVariantGenerator = cva(["flex"], {
  compoundVariants: [],
  defaultVariants: {
    direction: Direction.horizontal,
    fullHeight: false,
    fullWidth: false,
    grow: false,
    noWrap: false,
    spacing: Spacing.md
  },
  variants: {
    align: {
      [Align.center]: "items-center",
      [Align.end]: "items-end",
      [Align.start]: "items-start",
      [Align.stretch]: "items-stretch"
    },
    direction: {
      [Direction.horizontal]: "flex-row",
      [Direction.vertical]: "flex-col"
    },
    fullHeight: {
      false: "",
      true: "h-full"
    },
    fullWidth: {
      false: "",
      true: "w-full"
    },
    grow: {
      false: "grow-0",
      true: "grow",
      undefined: ""
    },
    justify: {
      [Justify.between]: "justify-between",
      [Justify.center]: "justify-center",
      [Justify.end]: "justify-end",
      [Justify.normal]: "justify-normal",
      [Justify.start]: "justify-start",
      [Justify.stretch]: "justify-stretch"
    },
    noWrap: {
      false: "flex-wrap",
      true: "flex-nowrap"
    },
    overflow: {
      [Overflow.auto]: "overflow-auto",
      [Overflow.hidden]: "overflow-hidden",
      [Overflow.visible]: "overflow-visible"
    },
    shrink: {
      false: "shrink-0",
      true: "shrink min-h-0 min-w-0",
      undefined: ""
    },
    spacing: {
      [Spacing.lg]: "gap-6",
      [Spacing.md]: "gap-4",
      [Spacing.none]: "gap-0",
      [Spacing.sm]: "gap-2",
      [Spacing.xl]: "gap-8",
      [Spacing.xs]: "gap-1"
    }
  }
});

/**
 * NOT INTENDED TO BE USED DIRECTLY.
 * @link ../../../group/src
 */
const Flex = forwardRef<HTMLDivElement, PropsWithChildren<FlexProps>>(function Flex(props: PropsWithChildren<FlexProps>, ref: ForwardedRef<HTMLDivElement>) {
  const {
    children,
    ...other
  } = props;
  return <RadixFlex ref={ref} className={FlexVariantGenerator(other)}>
        {children}
      </RadixFlex>;
});
export { Align, AlignValues, Direction, DirectionValues, Flex, Justify, JustifyValues, Overflow, OverflowValues, Spacing, SpacingValues };
export type { Alignments, Directions, FlexProps, Justifications, Overflows, Spacings };