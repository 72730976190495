"use client";

import { cva, cx } from "class-variance-authority";
import { Function as F, Predicate as P } from "effect";
import type { PropsWithChildren } from "react";
import { forwardRef, useId } from "react";
import { Color } from "@ender/shared/utils/theming";
import { Align, Justify, Spacing } from "../../../flex/src";
import { Group } from "../../../group/src";
import { LoadingSpinner } from "../../../loading-spinner/src";
import { Tooltip } from "../../../tooltip/src";
import type { ButtonProps } from "./shared-ds-button.types";
import { ButtonSize, ButtonVariant } from "./shared-ds-button.types";
const ButtonVariantGenerator = cva(["border border-transparent", "font-semibold", "rounded"], {
  compoundVariants: [{
    color: Color.slate,
    variant: ButtonVariant.filled,
    className: ["bg-wildcard-700", "hover:bg-wildcard-800 active:bg-wildcard-900"]
  }, {
    color: Color.slate,
    variant: ButtonVariant.outlined,
    className: ["border-wildcard-700 text-wildcard-700"]
  }, {
    color: Color.slate,
    variant: ButtonVariant.transparent,
    className: ["text-wildcard-700", "hover:text-wildcard-800 active:text-wildcard-900"]
  }],
  variants: {
    color: {
      [Color.green]: "wildcard-green",
      [Color.primary]: "wildcard-primary",
      [Color.red]: "wildcard-red",
      [Color.slate]: "wildcard-slate"
    },
    leftSection: {
      false: "pl-[--full-padding]",
      true: "pl-[--reduced-padding]"
    },
    loading: {
      false: "",
      true: ""
    },
    rightSection: {
      false: "pr-[--full-padding]",
      true: "pr-[--reduced-padding]"
    },
    size: {
      [ButtonSize.sm]: ["py-0.5 text-xs/normal", "[--full-padding:0.5rem] [--reduced-padding:0.375rem]"],
      [ButtonSize.md]: ["py-[calc(0.375rem-1px)] text-sm/standard", "[--full-padding:1rem] [--reduced-padding:0.75rem]"],
      [ButtonSize.lg]: ["py-[calc(0.625rem-1px)] text-sm/standard", "[--full-padding:1.5rem] [--reduced-padding:1.25rem]"],
      [ButtonSize.xl]: ["py-3.5 text-sm/standard", "[--full-padding:1.5rem] [--reduced-padding:1.25rem]"]
    },
    variant: {
      [ButtonVariant.filled]: ["bg-wildcard-500 text-white", "hover:bg-wildcard-600 active:bg-wildcard-700", "disabled:text-white disabled:bg-gray-200"],
      [ButtonVariant.outlined]: ["bg-white border-wildcard-500 text-wildcard-500", "hover:bg-wildcard-50 active:bg-wildcard-100", "disabled:text-white disabled:bg-gray-200 disabled:border-transparent"],
      [ButtonVariant.transparent]: ["bg-transparent text-wildcard-500", "hover:text-wildcard-600 hover:underline active:text-wildcard-700", "disabled:text-gray-200 disabled:no-underline", "[&]:[--full-padding:0] [&]:[--reduced-padding:0]"]
    }
  },
  defaultVariants: {
    color: Color.primary,
    leftSection: false,
    loading: false,
    rightSection: false,
    size: ButtonSize.md,
    variant: ButtonVariant.filled
  }
});
const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(function Button(props, ref) {
  const {
    children,
    disabled = false,
    disabledTooltip,
    leftSection,
    loading = false,
    onClick = F.constVoid,
    rightSection,
    tooltip,
    type = "button",
    ...other
  } = props;
  const attributes = {
    ...props,
    leftSection: Boolean(leftSection) || loading,
    rightSection: Boolean(rightSection)
  };
  const tooltipLabel = disabled ? disabledTooltip : tooltip;
  const labelId = useId();
  return <Tooltip label={tooltipLabel} disabled={!tooltipLabel}>
        <button type={type} disabled={disabled || loading} onClick={onClick} aria-labelledby={labelId} ref={ref} {...other} className={cx(ButtonVariantGenerator(attributes),
    //@ts-expect-error className is not an exposed prop, but is used when this is a child via Radix `asChild`
    other.className)}>
          <Group spacing={Spacing.sm} justify={Justify.center} align={Align.center} noWrap>
            <div className="contents text-[1lh]">
              {loading ? <LoadingSpinner /> : leftSection}
            </div>
            {P.isNotNullable(children) && <span className="grow whitespace-nowrap" id={labelId}>
                {children}
              </span>}
            <div className="contents text-[1lh]">{rightSection}</div>
          </Group>
        </button>
      </Tooltip>;
});
export { Button };